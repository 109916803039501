<template>
  <!-- 1.อาชีพค้าขาย -->
  <div class="p-fluid p-formgrid p-grid">
    <div class="p-field p-col-12 p-md-12">
      <DataTable
        :value="Trades"
        class="p-datatable-striped p-datatable-gridlines p-datatable-responsive"
      >
        <ColumnGroup type="header">
          <Row>
            <Column
              header="โปรดประเมินความสามารถของท่านในปัจจุบัน ในการทำกิจกรรมต่อไปนี้"
              :rowspan="2"
              headerStyle="width:40%;"
            />
            <Column header="ไม่แน่ใจ" headerClass="p-text-center" />
            <Column header="ทำไม่ได้เลย" headerClass="p-text-center" />
            <Column header="ยากมาก" headerClass="p-text-center" />
            <Column header="ยาก ปานกลาง" headerClass="p-text-center" />
            <Column header="ยากเล็กน้อย" headerClass="p-text-center" />
            <Column header="ไม่ยาก" headerClass="p-text-center" />
          </Row>
          <Row>
            <Column header="" headerClass="p-text-center" />
            <Column header="1" headerClass="p-text-center" />
            <Column header="2" headerClass="p-text-center" />
            <Column header="3" headerClass="p-text-center" />
            <Column header="4" headerClass="p-text-center" />
            <Column header="5" headerClass="p-text-center" />
          </Row>
        </ColumnGroup>

        <Column header="Name">
          <template #body="slotProps">
            <span v-html="slotProps.data['topic']"></span>
          </template>
        </Column>

        <!-- option 0-->
        <Column header="ไม่แน่ใจ(0)" bodyClass="p-text-center">
          <template #body="slotProps">
            <RadioButton
              :name="slotProps.index"
              :value="99"
              v-model="slotProps.data['select']"
              :required="true"
            />
          </template>
        </Column>
        <!-- /option 0-->

        <!-- option 1-->
        <Column header="ทำไม่ได้เลย(1)" bodyClass="p-text-center">
          <template #body="slotProps">
            <RadioButton
              :name="slotProps.index"
              :value="1"
              v-model="slotProps.data['select']"
              :required="true"
            />
          </template>
        </Column>
        <!-- /option 1-->

        <!-- option 2-->
        <Column header="ยากมาก(2)" bodyClass="p-text-center">
          <template #body="slotProps">
            <RadioButton
              :name="slotProps.index"
              :value="2"
              v-model="slotProps.data['select']"
              :required="true"
            />
          </template>
        </Column>
        <!-- /option 2-->

        <!-- option 3-->
        <Column header="ยาก ปานกลาง(3)" bodyClass="p-text-center">
          <template #body="slotProps">
            <RadioButton
              :name="slotProps.index"
              :value="3"
              v-model="slotProps.data['select']"
              :required="true"
            />
          </template>
        </Column>
        <!-- /option 3-->

        <!-- option  4-->
        <Column header="ยากเล็กน้อย(4)" bodyClass="p-text-center">
          <template #body="slotProps">
            <RadioButton
              :name="slotProps.index"
              :value="4"
              v-model="slotProps.data['select']"
              :required="true"
            />
          </template>
        </Column>
        <!-- /option 4-->

        <!-- option 5-->
        <Column header="ไม่ยาก(5)" bodyClass="p-text-center">
          <template #body="slotProps">
            <RadioButton
              :name="slotProps.index"
              :value="5"
              v-model="slotProps.data['select']"
              :required="true"
            />
          </template>
        </Column>
        <!-- /option 5-->
      </DataTable>
    </div>
  </div>
  <!-- /1.อาชีพค้าขาย -->
</template>

<script>
export default {
  name: 'Trade',
  data() {
    return {}
  },
  computed: {
    Trades() {
      return this.$store.state.prediction.labelsCareer['career_need_1']
    }
  }
}
</script>

<style>
</style>