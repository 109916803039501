<template>
  <Fieldset>
    <template #legend>
      <i class="pi pi-discord" style="fontsize: 2rem"></i>
      เฟส 1 - แบบจำลองการประเมินความสามารถในการประกอบอาชีพ
    </template>
    <form class="card" @submit.prevent="processML" id="validity">
      <div class="p-fluid p-formgrid p-grid">
        <!-- career -->
        <div class="p-field p-col-12 p-md-12">
          <label
            ><strong
              >ให้ผู้สูงอายุเลือกอาชีพหลักที่ต้องการทำในอนาคตอย่างน้อย 1
              อาชีพ</strong
            ></label
          >
          <div class="p-formgroup-inline">
            <div
              class="p-field-checkbox"
              v-for="(cr, value) in careers"
              :key="value"
            >
              <RadioButton
                :id="value"
                name="career_value"
                :value="value"
                v-model="career"
              />
              <label :for="value">{{ cr.text }}</label>
            </div>
          </div>
        </div>
      </div>
      <Trade v-if="career == 'career_need_1'" />
      <Farm v-else-if="career == 'career_need_2'" />
      <Rubber v-else-if="career == 'career_need_3'" />
      <Orcard v-else-if="career == 'career_need_4'" />
      <Rice v-else-if="career == 'career_need_5'" />
      <div class="p-d-flex p-jc-center">
        <Button
          type="submit"
          label="วิเคราะห์ผล"
          :icon="['pi pi-spinner', isLoading ? 'pi-spin' : '']"
          iconPos="left"
        />
      </div>
      <Divider align="left" type="solid">
        <b>อธิบายเพิ่มเติม</b>
      </Divider>
      <ul>
        <li>
          เปอร์เซ็นต์ความแม่นยำในการทำนายความสามารถในการทำงาน อยู่ที่
          {{ mapCareerPercentLabel }}
          <br />
          <strong v-if="careerValue != 'career_all'"
            >(
            <span style="color: red"
              >กรณี
              {{ mapCareerLabel }}
              ข้อมูลในการวิเคราะห์เพื่อสร้างแบบจำลองมีจำนวนน้อย
              <br />มีผลต่อความคลาดเคลื่อนในการประเมินความสามารถในการทำงานผู้สุงอายุ</span
            >
            )</strong
          >
        </li>
        <li>ผลวิเคราะห์ความสามารถในการทำงาน มี 3 ระดับ</li>
        <ol>
          <li style="color: red"><strong>มีปัญหาในการทำงาน</strong></li>
          <li style="color: green">
            <strong>สามารถทำงานได้</strong>
          </li>
          <li style="color: purple">
            <strong>สามารถทำงานได้ดี</strong>
          </li>
        </ol>
      </ul>
    </form>
  </Fieldset>
  <Dialog
    :header="'ผลการวิเคราะห์ความสามารถในการทำงาน:' + mapCareerLabel"
    v-model:visible="isModal"
    :style="{ width: '50vw' }"
  >
    <p class="p-d-flex p-jc-center" v-html="mapPredictScoreHtml"></p>
    <template #footer>
      <Button
        label="ปิด"
        icon="pi pi-times"
        @click="() => (isModal = false)"
        class="p-button-text"
      />
    </template>
  </Dialog>
</template>
<script>
import axios from 'axios'
import { initCustomValidity } from '@/HTMLElement'
import { mixins } from '../Mixins'

import Trade from './Trade'
import Farm from './Farm'
import Rubber from './Rubber'
import Orcard from './Orcard'
import Rice from './Rice'

export default {
  mixins: [mixins],
  layout: 'Private',
  data() {
    return {
      career: 'career_need_1',
      careers: {
        career_need_1: { text: 'อาชีพค้าขาย', percent: '56' },
        career_need_2: { text: 'อาชีพทำไร่/ทำสวน', percent: '60' },
        career_need_3: { text: 'อาชีพกรีดยาง', percent: '70' },
        career_need_4: { text: 'อาชีพทำสวนผลไม้', percent: '80' },
        career_need_5: { text: 'อาชีพทำนา', percent: '66' }
        //career_need_6: 'ไม่เลือก 7 อาชีพ'
      }
    }
  },
  watch: {
    career: function (newVal, oldVal) {
      console.log('newVal ::==', newVal)
      this.$store.commit('prediction/setLabelsCareer', {
        career: newVal,
        value: null
      })
    }
  },
  computed: {
    mapPredictScoreHtml() {
      const { text = '-', color } = this.grade[this.score] || { color: 'black' }
      return `<h1 style="color:${color};font-size:50px;font-weight: bold;"> ${text}</h1>`
    },
    mapCareerLabel() {
      const { text } = this.careers[this.career]
      return ` ${text}`
    },
    mapCareerPercentLabel() {
      const { percent } = this.careers[this.career]
      console.log('percent ::==', percent)
      return `${percent}%`
    }
  },
  mounted() {
    this.$nextTick(() => {
      console.log('initCustomValidity validate thai message !')
      initCustomValidity('validity')
    })
  },
  methods: {
    mappingFieldSneckcase(criteria) {
      let snecks = {
        farm6hrm: 'farm_6hrm',
        rubber6hrm: 'rubber_6hrm',
        orcard6hrm: 'orcard_6hrm',
        rice6hrm: 'rice_6hrm'
      }
      for (let x in criteria) {
        if (Object.prototype.hasOwnProperty.call(snecks, x)) {
          const newKey = snecks[x]
          const oriValue = criteria[x]
          criteria[newKey] = oriValue
          delete criteria[x]
        }
      }
      return criteria
    },
    async processML(event) {
      const { VUE_APP_API_ENDPOINT } = process.env
      console.log('event ::==', event)
      const dicisions = this.$store.state.prediction.labelsCareer
      const criteria = dicisions[this.career]?.reduce((obj, value) => {
        const { select, name } = value
        return { ...obj, [name]: select }
      }, {})
      const message = `career :: ${
        this.career
      } , dicisions ::== ${JSON.stringify(criteria)} `
      console.log('message ::==', message)
      //alert(message)
      let payload = {
        labels: this.mappingFieldSneckcase(criteria),
        loop: 100,
        area_id: 999,
        career_value: this.career,
        route: 'predict2'
      }
      this.message = null
      this.isLoading = true
      console.log('payload ::==', payload)

      try {
        const { data: result } = await axios({
          url: `${VUE_APP_API_ENDPOINT}/predict/train`, // labels=weight,bmi,waistline&area_id=999&algo=2
          data: payload,
          method: 'POST'
        })
        const { status, data } = result
        if (status.code == '01') {
          alert(
            'เกิดข้อผิดพลาดในระหว่างการวิเคราะห์ผล กรุณาติดต่อเจ้าหน้าที่ดูแลระบบ'
          )
        } else {
          //alert('data ::==' + JSON.stringify(data))
          var dataJson = JSON.parse(data);
          var score = dataJson.datas.score;
          this.message = JSON.stringify({
            ...data,
            grade: this.grade[score] || 'ดีมาก'
          })
          this.score = score
          this.isModal = true
        }
      } catch (error) {
        console.log('error ::==', error)
        alert(
          'เกิดข้อผิดพลาดในระหว่างการวิเคราะห์ผล กรุณาติดต่อเจ้าหน้าที่ดูแลระบบ'
        )
      }
      this.isLoading = false
    }
  },
  components: {
    Trade,
    Farm,
    Rubber,
    Orcard,
    Rice
  }
}
</script>

<style>
</style>